<template>
  <section>
    <div id="sidebar-article" style="background-color: white">
      <div style="cursor: pointer">
        <div
          style="
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <div @click="onLogoClick">
            <img
              :src="$store.state.logoImageUrl"
              style="height: 65px"
              alt=""
              class="mt-3 img-fluid"
            />
          </div>
        </div>
      </div>

      <div class="theLists" style="">
        <ul style="">
          <li v-on:click="closeNav()">
            <router-link to="/dashboard/home">
              <div class="side-link" :class="{ active: $route.name == 'Home' }">
                <span><i class="fas fa-home"></i> Home</span>
              </div>
            </router-link>
          </li>
          <li v-on:click="closeNav()">
            <router-link to="/dashboard/my-course-units">
              <div
                class="side-link"
                :class="{
                  active:
                    $route.name == 'MyCourseUnits' ||
                    $route.name == 'CourseUnitResources' ||
                    $route.name == 'ResourceDetails',
                }"
              >
                <span><i class="el-icon-notebook-2"></i> My Modules</span>
              </div>
            </router-link>
          </li>
          <li v-on:click="closeNav()">
            <router-link to="/dashboard/lectures">
              <div
                class="side-link"
                :class="{ active: $route.name == 'Lectures' }"
              >
                <span><i class="el-icon-monitor"></i> Lectures</span>
              </div>
            </router-link>
          </li>

          <li v-on:click="closeNav()">
            <router-link to="/dashboard/my-calendar">
              <div
                class="side-link"
                :class="{ active: $route.name == 'MyCalendar' }"
              >
                <span><i class="el-icon-date"></i> My Timetable</span>
              </div>
            </router-link>
          </li>

          <li v-on:click="closeNav()">
            <router-link to="/dashboard/course-works">
              <div
                class="side-link"
                :class="{
                  active:
                    $route.name == 'Courseworks' ||
                    $route.name == 'CourseworksAll',
                }"
              >
                <span><i class="el-icon-edit-outline"></i>Course Work</span>
              </div>
            </router-link>
          </li>

          <li
            v-on:click="closeNav()"
            :class="
              $store.getters.canViewExaminations ? 'show_list' : 'hide_list'
            "
          >
            <router-link to="/dashboard/examinations">
              <div
                class="side-link"
                :class="{
                  active:
                    $route.name == 'Examinations' ||
                    $route.name == 'ExamSubmissions',
                }"
              >
                <span><i class="el-icon-edit-outline"></i> Examinations</span>
              </div>
            </router-link>
          </li>

          <li v-on:click="closeNav()">
            <router-link to="/dashboard/student-results">
              <div
                class="side-link"
                :class="{ active: $route.name == 'StudentResults' }"
              >
                <span
                  ><i class="el-icon-document-checked"></i> Examination
                  Results</span
                >
              </div>
            </router-link>
          </li>

          <!-- <li v-on:click="closeNav()">
            <router-link to="/dashboard/student-financial-statements">
              <div
                class="side-link"
                :class="{active: $route.name == 'StudentFinancialStatements'}"
              >
                <span><i class="fas fa-coins"></i> Financial Statements</span>
              </div>
            </router-link>
          </li> -->

          <li v-on:click="closeNav()">
            <router-link to="/dashboard/transactions">
              <div
                class="side-link"
                :class="{ active: $route.name == 'Transactions' }"
              >
                <span><i class="el-icon-coin"></i> Financial Statements</span>
              </div>
            </router-link>
          </li>

          <li v-on:click="closeNav()">
            <router-link to="/dashboard/tutorials">
              <div
                class="side-link"
                :class="{
                  active:
                    $route.name == 'Tutorials' ||
                    $route.name == 'TutorialDetails',
                }"
              >
                <span><i class="el-icon-video-play"></i> Tutorials</span>
              </div>
            </router-link>
          </li>

          <!-- TODO: REAGAN UNCOMMENT THIS BLOCK OF CODE -->
          <!-- <li
            v-if="shouldShowElections"
            v-on:click="closeNav()"
          >
            <router-link to="/dashboard/elections/intro">
              <div
                class="side-link"
                :class="{ active: 
                  $route.name == 'Elections' || 
                   $route.name == 'Elections'
                 }"
              >
                <span><i class="fas fa-check-circle"></i> VU Elections</span>
              </div>
            </router-link>
          </li> -->

          <li v-on:click="closeNav()">
            <router-link to="/dashboard/elections/intro">
              <div
                class="side-link"
                :class="{
                  active:
                    $route.name == 'Elections' || $route.name == 'Elections',
                }"
              >
                <span><i class="el-icon-circle-check"></i> VU Elections</span>
              </div>
            </router-link>
          </li>

          <li v-on:click="closeNav()">
            <router-link to="/dashboard/inquiries">
              <div
                class="side-link"
                :class="{
                  active:
                    $route.name == 'inquiries' || $route.name == 'inquiries',
                }"
              >
                <span
                  ><i
                    class="el-icon-chat-line-square"
                    style="font-weight: 600"
                  ></i>
                  Chats / Inquiries</span
                >
              </div>
            </router-link>
          </li>

          <li v-on:click="closeNav()">
            <router-link to="/dashboard/academic-requests">
              <div
                class="side-link"
                :class="{ active: $route.name == 'AcademicRequests' }"
              >
                <span
                  ><i class="el-icon-edit-outline" style="font-weight: 600"></i>
                  Academic Requests</span
                >
              </div>
            </router-link>
          </li>
        </ul>
      </div>

      <div style="">
        <ul style="">
          <li v-on:click="closeNav()">
            <router-link to="/dashboard/settings">
              <div
                class="side-link"
                :class="{ active: $route.name == 'Settings' }"
              >
                <span><i class="el-icon-setting"></i> Settings</span>
              </div>
            </router-link>
          </li>
          <!-- <li v-on:click="closeNav()">
            <router-link to="/dashboard/help">
              <div
                class="side-link"
                :class="{ active: $route.name == 'Help' }"
              >
                <span><i class="far fa-question-circle"></i> Help</span>
              </div>
            </router-link>
          </li> -->
          <li v-on:click="onLogoutIntent">
            <div class="side-link">
              <span><i class="fas fa-sign-out-alt"></i> Logout</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import { APPLICATION_VERSION } from "@/helpers/constants";
export default {
  computed: {
    shouldShowElections() {
      return this.$store.state.isPesamoniUser || this.$store.state.isElec;
    },
  },

  methods: {
    closeNav() {
      document.getElementById("mySidenav").style.width = "0";
    },
    async onLogoutIntent() {
      this.closeNav();
      try {
        let logOut = await this.$confirm(
          "You will be immediately logged out of your account, Continue?",
          "Confirm Log out",
          {
            confirmButtonText: "Logout",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        );

        if (logOut) {
          this.logOut();
        }
      } catch (error) {
        // eslint-disable-line no-unused-vars
      }
    },

    onLogoClick() {
      const h = this.$createElement;
      this.$notify({
        title: "VClass Version",
        message: h(
          "span",
          { style: "color: black; text-align:left" },
          `You' running VClass Application Version 1.${APPLICATION_VERSION}`
        ),
        type: "info",
        duration: 6000,
      });
      this.$router.push({ path: "/dashboard/home" }).catch((error) => {
        if (
          error.name !== "NavigationDuplicated" &&
          !error.message.includes(
            "Avoided redundant navigation to current location"
          )
        ) {
          console.log(error);
        }
      });

      //Also try to check for updates
      this.serviceWorkerCheckUpdates();
    },

    async logOut() {
      let request = await this.httpRequest({
        url: "/students/logout",
        method: "POST",
        showFullScreenLoader: true,
        fullScreenLoaderMessage: "Logging you out...",
      });
      if (
        request &&
        request.success &&
        request.message == "LOGOUT_SUCCESSFUL"
      ) {
        this.$store.commit("LOGOUT_USER");
        this.$router.replace({ path: "/login" });
      }
    },

    serviceWorkerCheckUpdates() {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        let serviceWorker = registrations.find(
          (worker) =>
            worker.active &&
            worker.active.scriptURL.includes("service-worker.js")
        );
        //If we found it, attempt to fetch updates
        if (serviceWorker) {
          serviceWorker.update();
        }
      });
    },
  },
};
</script>

<style scoped>
.theLists {
  margin: 50px 0px;
  padding: 0px;
}
ul {
  padding: 0px;
}
ul li {
  list-style: none;
  text-align: left;
  font-weight: 400;
}
ul li i {
  margin-right: 5px;
}
.side-link {
  color: rgba(0, 0, 0, 0.74);
  font-weight: 500;
  padding: 10px 30px;
  cursor: pointer;
  transition: 0.5s;
}

.side-link.active {
  border-left: 6px solid var(--vu-red);
  color: var(--el-app-primary) !important;
  font-weight: 600;
}
.side-link:hover {
  background-color: #f6f6f6;
  padding: 10px 35px;
  transition: 0.5s;
  color: var(--el-app-primary);
}
.bg-white {
  background-color: white;
}
</style>
